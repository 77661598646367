import styles from 'components/primitives/form/Select.module.scss';
import { useEffect } from 'react';
import { Select } from 'components/primitives/form';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { getIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const emptyOption = { name: '', value: '' };

const DropdownField = ({
  fieldName,
  fieldTitle,
  validation,
  items,
  placeholderTextKey,
  required,
  valueChangeCallback,
  ...additionalProps
}) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const currentValue = getIn(values, fieldName);

  //this is needed to correlate initial value with available options
  useEffect(() => {
    if (!items.length || currentValue === undefined)
      return;

    let properValue = currentValue;
    if (currentValue === '' || currentValue === null)
      properValue = placeholderTextKey || !required ? '' : items[0].value;
    else if (!items.some(i => i.value === currentValue))
      properValue = '';

    if (properValue !== currentValue) {
      setFieldValue(fieldName, properValue, false);
      setFieldTouched(fieldName, false);
    }
  }, [items, currentValue]);

  if (!required)
    items = [emptyOption, ...items];

  const isInvalid = !!(getIn(errors, fieldName) && getIn(touched, fieldName));

  const handleDropdownChange = (fieldName, value) => {
    setFieldValue(fieldName, value);
     
    if (valueChangeCallback!==undefined) {
      valueChangeCallback(value);
    }
  };

  return (
    <Field
      items={items}
      fieldName={fieldName}
      fieldTitle={fieldTitle}
      validation={validation}
      as={Select}
      onChange={value => handleDropdownChange(fieldName, value)}
      className={styles.wide}
      placeholderTextKey={placeholderTextKey}
      required={required}
      isInvalid={isInvalid}
      {...additionalProps}
    />
  );
};

DropdownField.propTypes = {
  ...fieldPropTypes,
  items: PropTypes.array,
  placeholderTextKey: PropTypes.string,
  required: PropTypes.bool,
  valueChangeCallback: PropTypes.func,
};

DropdownField.notNative = true;

export default DropdownField;
